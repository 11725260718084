import { template as template_f0329497c4604113bcc37e64108fd5ae } from "@ember/template-compiler";
const FKText = template_f0329497c4604113bcc37e64108fd5ae(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
