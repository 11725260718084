import { template as template_c4a74dc5164143c496a1ec9b64f1c74f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_c4a74dc5164143c496a1ec9b64f1c74f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
