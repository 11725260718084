import { template as template_f0c90b78a4ef4032a1f961dd24800420 } from "@ember/template-compiler";
const FKLabel = template_f0c90b78a4ef4032a1f961dd24800420(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
